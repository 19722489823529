<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0">Personas Jurídicas</p>
        <v-btn
          color="primary"
          elevation="0"
          @click="modalAdd"
          v-if="searchInArray(actions, 'Agregar')"
        >
          <v-icon left light> mdi-plus </v-icon>
          <span class="d-none d-sm-flex">Agregar Persona Jurídica</span>
        </v-btn>
      </div>
    </v-banner>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat height="50">
                  <v-toolbar-title class="text-h5">
                    Lista de Personas Jurídicas
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon>mdi-file-excel</v-icon>
                          Descargar CSV
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="searchInArray(actions, 'Editar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="
                          selectLegal(item);
                          modalUpdate();
                        "
                        color="amber"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Eliminar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectLegal(item);
                          modalDelete();
                        "
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getLegals">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <UpdateLegal
      ref="mdlUpdate"
      :legal="slcLegal"
      :clean="cleanUpdate"
    ></UpdateLegal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UpdateLegal from "./UpdateLegal";

export default {
  name: "legal",
  components: { UpdateLegal },
  data: () => ({
    data: {
      load: true,
      headers: [
        {
          text: "Institución",
          value: "institution_name",
        },
        {
          text: "Correo electrónico",
          value: "email",
        },
        {
          text: "Teléfono",
          value: "phone",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
      data: [],
    },
    newLegal: {},
    slcLegal: {},
    dialogDelete: false,
    isLoading: false,
    search: "",
  }),
  async mounted() {
    this.getCurrentActions("Personas Jurídicas");
    await this.getLegals();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionalidad

    getLegals: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "juridic")
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    selectLegal(legal) {
      if (legal.photo_nit != null) {
        legal.doc_nit = legal.photo_nit;
        legal.photo_nit = null;
      }
      this.slcLegal = legal;
    },

    cleanAdd() {
      this.newLegal = {};
      this.getLegals();
    },

    modalAdd() {
      this.$refs.mdlAdd.changeModal();
    },

    modalUpdate() {
      this.$refs.mdlUpdate.changeModal();
    },

    cleanUpdate() {
      this.slcLegal = {};
      this.getLegals();
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      img: "getImg",
      actions: "getCurrentActions",
    }),
  },
};
</script>

<style>
.v-tooltip__content {
  pointer-events: initial;
}
</style>
