<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Modificar</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-divider />
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nombre completo"
                required
                outlined
                hide-details
                v-model="legal.name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Correo electrónico"
                type="email"
                required
                outlined
                hide-details
                v-model="legal.email"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Teléfono"
                type="text"
                required
                outlined
                hide-details
                v-model="legal.phone"
                v-mask="'####-####'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="NIT"
                type="text"
                required
                outlined
                hide-details
                v-model="legal.nit"
                v-mask="'####-######-###-#'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <embed
                :src="img + 'donor/nit/' + legal.doc_nit"
                frameborder="0"
                width="100%"
                height="300px"
              />
            </v-col>
            <v-col cols="12">
              <v-file-input
                label="Subir NIT de la institución"
                outlined
                hint="Copia del NIT en formato PDF"
                persistent-hint
                prepend-icon
                prepend-inner-icon="mdi-pdf-box"
                show-size
                accept="application/pdf"
                v-model="legal.photo_nit"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                label="¿Desea ser activar el registro de la Institución?"
                hide-details
                class="d-inline-block mx-auto"
                v-model="legal.active"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="update()"
          :loading="isLoading"
        >
          Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UpdateLegal",
  data() {
    return {
      donationGroups: [],
      isLoading: false,
      dialog: false,
    };
  },
  props: { legal: Object, groups: Array, clean: { type: Function } },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    update: function() {
      this.isLoading = true;
      this.legal.status = this.legal.active ? 1 : 3;
      this.$http
        .post(this.api + "juridic/" + this.legal.id_donor, this.legal)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    preview(event) {
      this.legal.src = URL.createObjectURL(event);
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      img: "getImg",
    }),
  },
};
</script>

<style></style>
